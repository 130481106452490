export default {
  modalController: null,
  modalContent: null,
  modalError: null,
  loadingModal: false,
  async openModal(target) {
    if (this.modalController) this.modalController.abort()

    const url = new URL(target, window.location)
    // prevent attacks
    if (!(window.location.hostname === url.hostname || !url.hostname.length)) return

    this.loadingModal = true
    this.modalController = new AbortController()
    const signal = this.modalController.signal

    try {
      const data = await fetch(url.href, { signal })
      const html = await data.text()

      this.modalContent = html.trim()
      this.toggleOverflow()
    } catch (error) {
      this.modalError = true
      console.warn(error)
    }

    this.loadingModal = false
  },
  closeModal() {
    if (this.modalController) this.modalController.abort()
    this.modalContent = null
    this.loadingModal = false
    this.modalError = false
    this.toggleOverflow(true)
  },
  toggleOverflow(flag = false, responsive = false) {
    if (responsive) document.body.classList.toggle('max-lg:overflow-hidden', flag)
    else document.body.style.overflow = flag ? null : 'hidden'
  },
  measure($el, varname, dimension = 'offsetHeight', target = document.body) {
    const measure = () => {
      target.style.setProperty(
        '--' + varname,
        $el[dimension] + 'px'
      )
    }
    const obs = new ResizeObserver(() => measure())
    obs.observe($el)
  }
}